import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environment/environment';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import '@progress/kendo-angular-intl/locales/de/all';
import { SharedModule } from '@shared/shared.module';
import { LoggerModule } from 'ngx-logger';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ErrorInterceptor } from './auth/error.interceptor';
import { CoreModule } from './core/core.module';
import { CodeModule } from './code/code.module';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

function initApp(translate: TranslateService) {
  return () => initTranslation(translate);
}

function initTranslation(translate: TranslateService) {
  translate.setDefaultLang(environment.defaultLanguage);

  const detectedLanguage = translate.getBrowserLang();
  const availableLanguages = new RegExp(`${environment.supportedLanguages.join('|')}`);
  const language = detectedLanguage?.match(availableLanguages) ? detectedLanguage : environment.defaultLanguage;
  return translate.use(language);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CodeModule,
    CoreModule,
    HeaderModule,
    FooterModule,
    SharedModule,
  ],
  providers: [
    provideAppInitializer(() => {
      const initializerFn = initApp(inject(TranslateService));
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: environment.defaultLanguage,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
