import { Component } from '@angular/core';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { Organization } from '@app/states/organization/organization.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'ruv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
})
export class HeaderComponent {
  public organization$!: Observable<Organization | undefined>;

  constructor(stateService: OrganizationStateService) {
    this.organization$ = stateService.organization$;
  }
}
